@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: rgb(240,253,244);
    /* background-color: ; */
    /* color: red; */
}

.OrganisationTree{
    /* background-color: red; */
    overflow-x: scroll;
}

.highcharts-credits{
    display: none;
}

.highcharts-title{
    display: none;
}


/* .node-template */
.node-template {
    text-align: center;
  }
  
  .node-template strong {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.25em;
  }
  
  .node-template span {
    display: flex;
    justify-content: center;
    padding: 0.25em;
  }
  
  .node-template button {
    margin-bottom: 0.5em;
  }
  
  /* h1 */
  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* .hierarchy-viewer */
  .hierarchy-viewer {
    /* background: #efefef; */
    display: block;
    margin: 20px;
    padding: 15px;
  }
  
  /* .level1 */
  .level1 {
    background-color: rgb(231, 228, 255);
  }
  
  /* .level2 */
  .level2 {
    background-color: rgb(255, 237, 227);
  }
  
  /* .level3 */
  .level3 {
    background-color: rgb(242, 248, 227);
  }
  
  .hidden{
    /* background-color: red; */
    display: none!important;
  }

  /* .OrganoGramImg{
    display: none;
  }


  .hierarchy-viewer:hover .OrganoGramImg {
    display: block !important;
  }
   */

/* Initially hide the .OrganoGramImg */
.node-template .OrganoGramImg {
    display: none;
  }
  
  /* Display the .OrganoGramImg when .hierarchy-viewer is hovered */
  .hierarchy-viewer:hover .node-template .OrganoGramImg {
    display: block;
  }
  
   